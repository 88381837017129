import * as baguetteBox from "baguettebox.js";
import EmblaCarousel, { EmblaCarouselType } from "embla-carousel";
import "../css/entry.css";
import {
  DataAnalyticsEventAttr,
  SiteWindow,
  UsercentricsUI,
} from "./@types/types";
import { onCollapseToggle } from "./collapse";
import { registerErrorReporting } from "./error-reporting";
import { initNewsletterRegistrationForm } from "./newsletter-registrations";
import {
  convertObjectValuesToStrings,
  currentLanguage,
  getSearchParamsRaw,
  isDataAnalyticsEventAttr,
  reportError,
  triggerAnalyticsEvent,
  trustyouKey,
} from "./utils";

registerErrorReporting();

declare global {
  interface Window extends SiteWindow {}
}

// Handle mobile menu
document
  .querySelector<HTMLAnchorElement>(".js-menu-button")
  ?.addEventListener("click", (event) => {
    event.preventDefault();
    document.body.classList.toggle("slide-out");
    const { hash } = <HTMLAnchorElement>event.target;
    document.querySelector(hash)?.classList.toggle("open");
  });

// Header images
const initHeaderSlider = () => {
  const headerImagesEl =
    document.querySelector<HTMLElement>(".js-header-images");

  if (!headerImagesEl) return;

  const headerSlider = EmblaCarousel(headerImagesEl, { loop: true });

  const runAutoplay = (slider: EmblaCarouselType, runCount = 1) => {
    // Only slide automatically 5 times
    if (runCount > 5) return;
    setTimeout(() => {
      slider.scrollNext();
      runAutoplay(slider, runCount + 1);
    }, 1e4);
  };

  runAutoplay(headerSlider);
};

initHeaderSlider();

// General image galleries
{
  Array.from(
    document.querySelectorAll<HTMLElement>(".js-image-gallery"),
  ).forEach((galleryElement) => {
    const gallery = EmblaCarousel(galleryElement, {
      slidesToScroll: 3,
      loop: true,
    });

    galleryElement
      .querySelector<HTMLButtonElement>(".js-image-gallery-prev")
      ?.addEventListener("click", () => gallery.scrollPrev());

    galleryElement
      .querySelector<HTMLButtonElement>(".js-image-gallery-next")
      ?.addEventListener("click", () => gallery.scrollNext());
  });
}

let map: google.maps.Map;
let panorama: google.maps.StreetViewPanorama;

// Detail page
const accommodationDetailEl = document.querySelector<HTMLDivElement>(
  ".accommodation-detail",
);

const renderTrustYouTemplate = ({
  response: { category_list },
}: TrustYouResponse): string => {
  const renderTemplate = ({ category_name, score }: TrustYouCategory) => `
    <tr>
      <td>${category_name}</td>
      <td class="percentage">${score}&nbsp;%</td>
      <td class="score">
        <div>
          <div class="js-progress-bar progress-bar" data-percentage="${score}"></div>
        </div>
      </td>
    </tr>
    `;

  return category_list.map(renderTemplate).join("\n");
};

const getTrustYouData = async (trustId: string): Promise<TrustYouResponse> => {
  const url = `https://api.trustyou.com/hotels/${trustId}/meta_review.json?lang=${currentLanguage}&key=${trustyouKey}`;
  const response = await fetch(url);

  if (!response.ok) {
    throw new Error("Error while loading data from TrustYou");
  }

  const data: TrustYouResponse = await response.json();
  return data;
};

const loadTrustYou = (containerElement: HTMLElement) => {
  const trustId = containerElement.dataset["trustId"];

  if (!trustId) return;

  const observer = new IntersectionObserver((entries) => {
    entries.forEach(async (entry) => {
      if (!entry.isIntersecting) return;
      const data = await getTrustYouData(trustId);
      const content = renderTrustYouTemplate(data);

      containerElement
        .querySelector("table tbody")
        ?.insertAdjacentHTML("beforeend", content);

      setTimeout(() => {
        Array.from(
          containerElement.querySelectorAll<HTMLElement>(".js-progress-bar"),
        ).forEach((element) => {
          const percentage = element.dataset["percentage"];
          percentage && (element.style.width = percentage + "%");
        });
      });
    });
  });

  observer.observe(containerElement);
};

const initAccommodationDetailSlider = () => {
  const selectThumbBtn = (
    mainSlider: EmblaCarouselType,
    thumbnailSlider: EmblaCarouselType,
  ) => {
    const previous = mainSlider.previousScrollSnap();
    const selected = mainSlider.selectedScrollSnap();
    thumbnailSlider.slideNodes()[previous]?.classList.remove("is-selected");
    thumbnailSlider.slideNodes()[selected]?.classList.add("is-selected");
  };

  const followMainCarousel =
    (mainSlider: EmblaCarouselType, thumbnailSlider: EmblaCarouselType) =>
    () => {
      thumbnailSlider.scrollTo(mainSlider.selectedScrollSnap());
      selectThumbBtn(mainSlider, thumbnailSlider);
    };

  const mainSliderEl = document.querySelector<HTMLDivElement>(
    ".js-accommodation-main-slider",
  );

  const thumbnailSliderEl = document.querySelector<HTMLDivElement>(
    ".js-accommodation-thumbnail-slider",
  );

  const thumbnailSliderPrevEl = document.querySelector<HTMLButtonElement>(
    ".js-accommodation-thumbnail-slider-nav-prev",
  );

  const thumbnailSliderNextEl = document.querySelector<HTMLButtonElement>(
    ".js-accommodation-thumbnail-slider-nav-next",
  );

  if (
    !mainSliderEl ||
    !thumbnailSliderEl ||
    !thumbnailSliderPrevEl ||
    !thumbnailSliderNextEl
  ) {
    return;
  }

  const mainSlider = EmblaCarousel(mainSliderEl, { loop: true });

  const thumbnailSlider = EmblaCarousel(thumbnailSliderEl, {
    containScroll: "keepSnaps",
    loop: true,
  });

  const syncThumbCarousel = followMainCarousel(mainSlider, thumbnailSlider);
  mainSlider.on("select", syncThumbCarousel);
  thumbnailSlider.on("init", syncThumbCarousel);

  thumbnailSliderPrevEl.addEventListener("click", () => {
    thumbnailSlider.scrollPrev();
    mainSlider.scrollPrev();
  });

  thumbnailSliderNextEl.addEventListener("click", () => {
    thumbnailSlider.scrollNext();
    mainSlider.scrollNext();
  });
};

const toggleMapHide = (
  gmapEl: HTMLElement | null,
  gstreetEl: HTMLElement | null,
) => {
  const mapLinksEl =
    accommodationDetailEl?.querySelectorAll(".map-links button");
  const galleryEl = document.querySelector<HTMLDivElement>(".gallery");

  mapLinksEl &&
    Object.values(mapLinksEl)?.map((el) => el.classList.remove("active"));
  if (!galleryEl) return;
  galleryEl.hidden = Boolean(
    gstreetEl?.classList.contains("loaded") ||
      gmapEl?.classList.contains("loaded"),
  );
};

const onAccommodationDetailPage = async (
  accommodationDetailEl: HTMLElement,
) => {
  // Maps
  const searchBoxEl =
    accommodationDetailEl.querySelector<HTMLElement>(".search-box");
  const roomListEl =
    accommodationDetailEl.querySelector<HTMLDivElement>(".room-list");
  const gmapEl = document.querySelector<HTMLDivElement>(".gmap");
  const gstreetEl = document.querySelector<HTMLDivElement>(".gstreet");

  const { Gmap } = await import("./map");

  accommodationDetailEl
    .querySelector(".map-view")
    ?.addEventListener("click", () => {
      gmapEl?.classList.toggle("loaded");
      gstreetEl?.classList.remove("loaded");

      toggleMapHide(gmapEl, gstreetEl);

      if (map) {
        google.maps.event.trigger(map, "resize");
        return;
      }

      gmapEl &&
        Gmap.init(gmapEl, (map: google.maps.Map) => {
          const lat = gmapEl?.dataset["lat"];
          const lng = gmapEl?.dataset["lng"];

          if (!lat || !lng) return;

          const latLng = new google.maps.LatLng(+lat, +lng);

          new google.maps.Marker({
            position: latLng,
            map,
            title: gmapEl?.dataset["title"] ?? null,
          });
        });
    });

  Gmap.buildStreetView = () => {
    if (panorama) {
      google.maps.event.trigger(panorama, "resize");
      return;
    }

    if (!gstreetEl) return;

    const lat = gstreetEl.dataset["lat"];
    const lng = gstreetEl.dataset["lng"];
    const heading = gstreetEl.dataset["rot"];
    const pitch = gstreetEl.dataset["pit"];
    const zoom = gstreetEl.dataset["zoom"];

    if (!lat || !lng || !heading || !pitch || !zoom) {
      return;
    }
    panorama = new google.maps.StreetViewPanorama(gstreetEl, {
      position: { lat: +lat, lng: +lng },
      pov: { heading: +heading, pitch: +pitch },
      zoom: +zoom,
    });
  };

  accommodationDetailEl
    .querySelector<HTMLDivElement>(".street-view")
    ?.addEventListener("click", (event) => {
      gstreetEl?.classList.toggle("loaded");
      gmapEl?.classList.remove("loaded");

      toggleMapHide(gmapEl, gstreetEl);

      (<HTMLElement | null>event?.target)?.classList.add("active");

      Gmap.load("buildStreetView");
    });

  searchBoxEl
    ?.querySelector(".submit")
    ?.addEventListener("click", () => roomListEl?.scrollIntoView());

  initAccommodationDetailSlider();

  const trustYouElement =
    document.querySelector<HTMLElement>(".js-trustyou-stats");
  trustYouElement && loadTrustYou(trustYouElement);
};

accommodationDetailEl && onAccommodationDetailPage(accommodationDetailEl);

interface TrustYouCategory {
  category_id: string;
  category_name: string;
  score: number;
}

interface TrustYouResponse {
  response: {
    category_list: TrustYouCategory[];
  };
}

const loadAvailabilityIfNeeded = async () => {
  const el = document.querySelector<HTMLElement>("#js-availability");
  if (!el) return;
  const { renderAvailability } = await import("./availability");
  renderAvailability(el);
};

loadAvailabilityIfNeeded();

initNewsletterRegistrationForm();

const handleBackToSearchClick = (target: HTMLElement, event: MouseEvent) => {
  const targetLink = target.closest<HTMLAnchorElement>(".js-back-to-search");

  if (!targetLink) return;
  event.preventDefault();

  const searchParamsRaw = getSearchParamsRaw();
  const hotelId = targetLink.dataset["hotelId"];
  location.href = `${targetLink.href}?${new URLSearchParams(
    convertObjectValuesToStrings(searchParamsRaw),
  ).toString()}#h${hotelId}`;
};

const handleAnalyticsEventTracking = (target: HTMLElement) => {
  const triggerElement = target.closest<HTMLElement>("[data-analytics-event]");

  if (!triggerElement) return;
  const stringData = triggerElement.dataset["analyticsEvent"];
  if (!stringData) {
    reportError(
      new Error("No value for the data-analytics-event HTML attribute"),
    );
    return;
  }

  const data: DataAnalyticsEventAttr = JSON.parse(stringData);
  if (!isDataAnalyticsEventAttr(data)) {
    reportError(new Error("Invalid JSON structure for data-analytics-event"));
    return;
  }

  triggerAnalyticsEvent({
    eventAction: data.action,
    eventCategory: data.category,
    eventLabel: data.label,
    eventValue: data.value,
  });
};

// General click handler
document.addEventListener("click", (event) => {
  const target = <HTMLElement | null>event.target;
  if (!target) return;

  handleBackToSearchClick(target, event);
  handleAnalyticsEventTracking(target);
  onCollapseToggle(target, event);
});

const initSearchIfNeeded = async () => {
  const el = document.querySelector<HTMLElement>(".js-search-container");
  if (!el) return;
  const { initSearch } = await import("./init-search");
  initSearch(el);
};

initSearchIfNeeded();

const initMainSearchIfNeeded = async () => {
  const el = document.querySelector<HTMLElement>(".js-main-search");
  if (!el) return;

  const { initMainSearch } = await import("./init-main-search");
  initMainSearch(el);
};

baguetteBox.run(".js-lightbox-gallery");

initMainSearchIfNeeded();

// Show Usercentrics CMP v3 modal on click
document
  .querySelector('[href="#show-usercentrics-modal"]')
  ?.addEventListener("click", (event) => {
    event.preventDefault();
    (<UsercentricsUI>window).__ucCmp?.showSecondLayer();
  });
